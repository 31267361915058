[data-theme=local] {
  /* Background Colors */
  --bg-color: #B2D5EE;
  --nav-color: #B2D5EE;
  --sidebar-select-color: var(--gray-100);
  /* Top Header */
}
[data-theme=local] .body {
  background-color: var(--scroll-color);
}
[data-theme=local] .dropdown-menu {
  background-color: var(--bg-color);
  border: 1px solid var(--bg-color);
}
[data-theme=local] .dropdown-item:hover,
[data-theme=local] .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: var(--gray-100);
}
[data-theme=local] .widget {
  box-shadow: 0px 0px 5px var(--gray-300);
}
[data-theme=local] .widget.shortcut-widget-box {
  border-radius: var(--border-radius-sm);
}
[data-theme=local] [data-page-route=Workspaces] .layout-main-section {
  background-color: #F0F7FC;
  box-shadow: 0 0 5px lightgray;
  border-radius: var(--border-radius-lg);
  padding: var(--padding-sm);
}
[data-theme=local] .spacer {
  background-color: #F0F7FC;
  box-shadow: none;
}
[data-theme=local] .btn-primary:hover {
  color: #fff;
  background-color: #4285F4;
  border-color: #4285F4;
}
[data-theme=local] .navbar {
  background: var(--nav-color) !important;
  /*     height: 50px; */
}
[data-theme=local] .navbar-expand {
  background-color: var(--nav-color);
}
[data-theme=local] .datatable .dt-row:nth-child(odd) {
  background-color: rgba(240, 244, 248, 0.5490196078);
}
[data-theme=local] .datatable .dt-row:nth-child(even) {
  background-color: #ffffff;
}
[data-theme=local] .datatable {
  background-color: white;
}
[data-theme=local] .datatable .dt-cell {
  color: var(--text-color) !important;
  background-color: transparent !important;
}
[data-theme=local] .grid-heading-row .filter-row {
  background-color: white;
}
[data-theme=local] table {
  border-collapse: collapse;
  background-color: var(--gray-200);
}
[data-theme=local] th,
[data-theme=local] td {
  text-align: center;
  padding: 8px;
  max-width: 240px;
  overflow: hidden;
}
[data-theme=local] th {
  text-align: center;
  background-color: #f2f2f2;
}
[data-theme=local] tr:nth-child(even) {
  background-color: var(--bg-color);
}

#btn-deepseek {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

p li ul {
  margin: 10px !important;
}

.list-item {
  display: flex !important;
}